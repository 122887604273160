.os-homepage {
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  object-fit: contain;
  background-image: url('../../assets/album_no_logo.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: inherit;
}

.os-home-header {
  font-family: 'Aboreto', cursive;
  font-size: 3em;
  margin-top: 2em;
  color: white;
  text-shadow: 3px 3px black;
}

@media (max-width: 480px) {
  .os-home-header {
    font-size: 2em;
    margin-top: 2em;
  }
}
