.os-contact-photo {
  max-width: 750px;
  margin: 0 auto;
}

.os-contactpage {
  max-width: 900px;
  margin: auto;
}

.os-info {
  margin-top: 24px;
  font-family: 'Aboreto', cursive;
}

.os-label {
  margin-right: 30px;
  font-family: 'Cinzel Decorative', cursive;
}

.os-divider {
  margin-top: 30px;
}

@media (max-width: 480px) {
  .os-contact-photo {
    max-width: 90%;
  }

  .os-info {
    font-size: 1em;
  }

  .os-label {
    margin-right: 5px;
  }

  .os-divider {
    margin-left: 16px;
    margin-right: 16px;
  }
}
