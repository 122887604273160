.os-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.os-modal-body {
  background-color: #1a1a1a;
  width: 500px;
  min-height: 50%;
  max-height: 90%;
  position: relative;
  opacity: 1;
  border-radius: 10px;
  padding: 8px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.os-close-modal {
  position: absolute;
  top: 4px;
  right: 16px;
  font-size: 30px;
  transition: color 0.5s;
}

.os-close-modal:hover {
  color: #fccca4;
}

.wide {
  width: 900px;
}

.os-close-modal:hover {
  cursor: pointer;
}

.os-modal-body::-webkit-scrollbar {
  display: none;
}

@media (max-width: 480px) {
  .os-modal-body {
    width: 90%;
    height: 90%;
  }

  .os-close-modal {
    font-size: 20px;
  }

  .wide {
    width: 90%;
  }
}
