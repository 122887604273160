* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  min-height: 100%;
  margin-bottom: 0;
  background-color: black;
  color: white;
}

body {
  /* min-height: 100%;
  height: 100%; */
  margin-bottom: 0;
  background-color: black;
  color: white;
}

#root {
  min-height: 100%;
  height: 100%;
  margin-bottom: 0;
  background-color: black;
  color: white;
}
