.obsidianSkies {
  text-align: center;
}

.os-aboreto {
  font-family: 'Aboreto', cursive;
}

.os-cinzel {
  font-family: 'Cinzel Decorative', cursive;
}

.os-cormorant {
  font-family: 'Cormorant SC', serif;
}

.os-crimson {
  font-family: 'Crimson Text', serif;
}

.os-grenze {
  font-family: 'Grenze Gotisch', cursive;
}

.os-nanum {
  font-family: 'Nanum Myeongjo', serif;
}
